<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getAccessOverview, iamType } from "@/services/api/iam.api";
import { mdiTrashCanOutline } from "@mdi/js";
import { emptyGuid, isEmptyGuid } from "@/composables/miscellaneous.js";
import GridPagination from "@/components/general/GridPagination.vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";

const emits = defineEmits(["remove-relationship"]);

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  preFilter: {
    type: Array,
    default: () => [],
  },
  viaAccess: undefined,
  currentType: undefined,
  addLink: undefined,
  allowRemove: undefined,
  refreshKey: undefined,
});

const filterData = ref([]);

const headers = computed(() => {
  let newHeaders = [{ text: "", value: "actions", sortable: false, width: 20 }];
  switch (props.currentType) {
    case iamType.User:
      newHeaders.push({ text: "Naam", value: "userName" });
      newHeaders.push({ text: "Omschrijving", value: "userDescription" });
      newHeaders.push({ text: "Actief", value: "userValid", sortable: false });
      newHeaders.push({ text: "Groep", value: "userGroupName" });
      if (props.viaAccess.domain != iamType.Resource) {
        newHeaders.push({ text: "Resource", value: "resourceReference" });
      }
      if (props.viaAccess.domain != iamType.Project) {
        newHeaders.push({ text: "Project", value: "projectName" });
      }
      if (props.viaAccess.domain != iamType.Role) {
        newHeaders.push({ text: "Rol", value: "roleName" });
      }
      break;
    case iamType.UserGroup:
      newHeaders.push({ text: "Naam", value: "userGroupName" });
      newHeaders.push({ text: "Omschrijving", value: "userGroupDescription" });
      if (props.viaAccess.domain != iamType.Resource) {
        newHeaders.push({ text: "Resource", value: "resourceReference" });
      }
      if (props.viaAccess.domain != iamType.Project) {
        newHeaders.push({ text: "Project", value: "projectName" });
      }
      if (props.viaAccess.domain != iamType.Role) {
        newHeaders.push({ text: "Rol", value: "roleName" });
      }
      break;
    case iamType.Resource:
      newHeaders.push({ text: "Referentie", value: "resourceReference" });
      newHeaders.push({ text: "Omschrijving", value: "resourceDescription" });
      if (props.viaAccess.domain != iamType.User) {
        newHeaders.push({ text: "Gebruiker", value: "userName" });
      }
      if (props.viaAccess.domain != iamType.UserGroup) {
        newHeaders.push({ text: "Groep", value: "userGroupName" });
      }
      if (props.viaAccess.domain != iamType.Project) {
        newHeaders.push({ text: "Project", value: "projectName" });
      }
      if (props.viaAccess.domain != iamType.Role) {
        newHeaders.push({ text: "Rol", value: "roleName" });
      }
      break;
    case iamType.Project:
      newHeaders.push({ text: "Naam", value: "projectName" });
      newHeaders.push({ text: "Omschrijving", value: "projectDescription" });
      if (props.viaAccess.domain != iamType.User) {
        newHeaders.push({ text: "Gebruiker", value: "userName" });
      }
      if (props.viaAccess.domain != iamType.UserGroup) {
        newHeaders.push({ text: "Groep", value: "userGroupName" });
      }
      if (props.viaAccess.domain != iamType.Resource) {
        newHeaders.push({ text: "Resource", value: "resourceReference" });
      }
      if (props.viaAccess.domain != iamType.Role) {
        newHeaders.push({ text: "Rol", value: "roleName" });
      }
      break;
    case iamType.Role:
      newHeaders.push({ text: "Naam", value: "roleName" });
      newHeaders.push({ text: "Omschrijving", value: "roleDescription" });
      if (props.viaAccess.domain != iamType.User) {
        newHeaders.push({ text: "Gebruiker", value: "userName" });
      }
      if (props.viaAccess.domain != iamType.UserGroup) {
        newHeaders.push({ text: "Groep", value: "userGroupName" });
      }
      if (props.viaAccess.domain != iamType.Resource) {
        newHeaders.push({ text: "Resource", value: "resourceReference" });
      }
      if (props.viaAccess.domain != iamType.Project) {
        newHeaders.push({ text: "Project", value: "projectName" });
      }
      break;
  }
  newHeaders.push({ text: "Geldig vanaf", value: "validFrom" });
  newHeaders.push({ text: "Geldig tot", value: "validTill" });
  newHeaders.push({ text: "id", value: "id" });

  if (props.allowRemove) {
    newHeaders.push({ text: "", value: "delete-link", sortable: false, width: 20, showOnAllowDelete: true });
  }
  return newHeaders;
});

watch(
  () => props.preFilter,
  () => {
    console.log("AccessGrid preFilter: ", props.preFilter);
    filterData.value = [...props.preFilter];
  },
  { immediate: true, deep: true }
);

watch(
  () => props.refreshKey,
  () => {
    fetchFirstPage();
  }
);

watch(
  () => filterData.value,
  () => {
    console.log("AccessGrid filterData: ", filterData.value);
    fetchFirstPage();
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  fetchFirstPage();
});

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  let response = await getAccessOverview(pageNumber0, pageSize, sortBy, sortDesc, props.viaAccess, props.currentType, filterData.value);
  //maak een index voor grid
  response.data.data = response.data.data.map((item, index) => ({
    index: index,
    ...item,
  }));
  return response;
}

function actionLink(item) {
  switch (props.currentType) {
    case iamType.User:
      return { name: "IAM-UserDetails", params: { userId: item.userId } };
    case iamType.UserGroup:
      return { name: "IAM-UserGroupDetails", params: { groupId: item.userGroupId } };
    case iamType.Resource:
      return { name: "IAM-ResourceDetails", params: { resourceId: item.resourceId } };
    case iamType.Project:
      return { name: "IAM-ProjectDetails", params: { projectId: item.projectId } };
    case iamType.Role:
      return { name: "IAM-RoleDetails", params: { roleId: item.roleId } };
    default:
      return undefined;
  }
}

function allowDeleteForDomain(item) {
  // console.log("AccessGrid allowDeleteForDomain: ", props.viaAccess.domain.single,item.userId,item.userGroupId );
  return (props.viaAccess.domain == iamType.User && isEmptyGuid(item.userGroupId)) || (props.viaAccess.domain == iamType.UserGroup && (!item.userId || item.userId === emptyGuid));
}

function onRemoveLink(id) {
  emits("remove-relationship", id);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right">
        <v-btn v-if="props.addLink" dark class="primary mt-4" :to="props.addLink">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-right">
        <slot name="add-relation"></slot>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      item-key="index"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="actionLink(item)" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.userValid`]="{ item }">
        {{ item.userValid === true ? "Ja" : "Nee" }}
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        {{ formatDateUtc(item.validFrom) }}
      </template>
      <template v-slot:[`item.validTill`]="{ item }">
        {{ formatDateUtc(item.validTill) }}
      </template>

      <template v-slot:[`item.delete-link`]="{ item }">
        <v-tooltip bottom v-if="allowDeleteForDomain(item)">
          <template v-slot:activator="{ on }">
            <v-btn @click="onRemoveLink(item.id)" icon small plain v-on="on">
              <v-icon small>{{ mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Verwijder koppeling</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
